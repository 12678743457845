import cn from "classnames";
import random from "lodash/random";
import React from "react";
import styles from "./styles.module.css";

const Circle = ({ width = 0 }) => {
  const size = (width / 3) * 2;

  const style = {
    width: size,
    height: size,
    borderRadius: size
  };

  return (
    <span
      className={cn(styles.circle, random(0, 1) === 0 ? styles.masculine : styles.feminine)}
      style={style}
    />
  );
};

export default Circle;
