import cn from "classnames";
import React from "react";
import Highlight from "../../../Highlight";
import styles from "./styles.module.css";

const WordList = ({ words, onClick }) => (
  <div className={styles.wrap}>

    <span className={cn(styles.heading, "type-16")}>
      Gender-biased words found:
    </span>

    <ol className={styles.list}>
      {words.map(({ word, type, key, index, length }, i) => (
        <li className="type-18" key={i}>
          <button className={styles.button} onClick={() => onClick(key, index, length)}>
            <Highlight type={type}>
              {word}
            </Highlight>
          </button>
        </li>
      ))}
    </ol>
  </div>
);

export default WordList;
