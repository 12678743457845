import cn from "classnames";
import React from "react";
import FeminineHighlight from "../../Highlight/FeminineHighlights";
import MasculineHighlight from "../../Highlight/MasculineHighlight";
import styles from "./styles.module.css";
import WordList from "./WordList";
import WordRatio from "./WordRatio";

const JobStats = ({ words, onClick }) => (
  <div className={cn(styles.wrap, "type-24")}>
    {words.length === 0 ? (
      <span>
        You're not using any <MasculineHighlight bold/> or <FeminineHighlight bold/> words.
      </span>
    ) : (
      <>
        <WordRatio words={words}/>
        <WordList words={words} onClick={onClick}/>
      </>
    )}
  </div>
);

export default JobStats;
