import cn from "classnames";
import findIndex from "lodash/findIndex";
import React from "react";
import Highlight from "../../../../components/Highlight";
import { findGenderedWords } from "../../../../util/words";
import styles from "./styles.module.css";

export let decoratedWords = {};

export function highlightStrategy (contentBlock, callback) {
  const key = contentBlock.getKey();
  const words = findGenderedWords(contentBlock);

  words.forEach(({ index, length }) => {
    callback(index, index + length);
  });

  decoratedWords[key] = words;
}

export function HighlightSpan (props) {
  const { decoratedText, blockKey } = props;
  const index = findIndex(decoratedWords[blockKey], { word: decoratedText });

  // if we can't find the word then just return the word
  if (index === -1) {
    return (
      <span
        data-offset-key={props.offsetKey}
      >
        {props.children}
      </span>
    );
  }

  const { type } = decoratedWords[blockKey][index];

  return (
    <span
      data-offset-key={props.offsetKey}
    >
      <Highlight type={type}>
        {props.children}
      </Highlight>
    </span>
  );
}

export function pasteStrategy (contentBlock, callback) {
  const text = contentBlock.getText();
  const regex = /(Paste your text here to start.)/igm;

  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;

    callback(start, start + matchArr[0].length);
  }
}

export function PasteSpan (props) {
  return (
    <span
      className={cn(styles.pasteHighlight)}
      data-offset-key={props.offsetKey}
    >
      {props.children}
    </span>
  );
}
