import cn from "classnames";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../CloseIcon";
import styles from "../styles.module.scss";

const ClearButton = ({ onClear, hasText }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (hasText) {
      setShow(true);
    }

    if (!hasText && show) {
      const id = setTimeout(() => {
        setShow(false);
      }, 300);

      return () => clearTimeout(id);
    }

  }, [setShow, hasText, show]);

  const props = {};

  if (!hasText) {
    props.tabIndex = "-1";
  }

  return (
    <button
      onClick={onClear}
      className={cn(styles.clearButton, { [styles.fade]: !hasText, [styles.hide]: !show }, "type-16")}
      {...props}
    >
      <CloseIcon size={20}/>
      <span>
        Clear
      </span>
    </button>
  );
};

export default ClearButton;
