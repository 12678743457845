import { EditorState, SelectionState } from "draft-js";
import orderBy from "lodash/orderBy";
import words from "../data/words.yaml";

const { masculine, feminine } = words;

export const findGenderedWords = (contentBlock) => {
  const key = contentBlock.getKey();
  const text = contentBlock.getText();
  const matches = [];

  if (contentBlock.getLength() === 0) {
    return matches;
  }

  const findWord = (originalWord, type) => {
    const regex = new RegExp(`\\b${originalWord}\\b`, "igm");

    let matchArray;

    while ((matchArray = regex.exec(text)) !== null) {
      const [word] = matchArray;

      matches.push({
        key,
        originalWord,
        word,
        type,
        index: matchArray.index,
        length: word.length
      });
    }
  };

  masculine.forEach((originalWord) => findWord(originalWord, "m"));
  feminine.forEach((originalWord) => findWord(originalWord, "f"));

  return orderBy(matches, "index");
};

export const moveSelectionToEnd = (editorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.first().getKey();
  const length = blockMap.first().getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  return EditorState.forceSelection(editorState, selection);
};
