import cn from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

const FadeIn = ({ Tag = "span", show = true, delay = 200, children }) => {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    if (!show) {
      setShowing(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowing(true);
    }, delay);

    // clear the timeout on destroy
    return () => clearTimeout(timer);
  }, [show, delay, setShowing]);

  return (
    <Tag className={cn(styles.animate, showing ? styles.show : styles.hide)}>
      {children}
    </Tag>
  );
};

export default FadeIn;
