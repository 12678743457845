import cn from "classnames";
import React, { useState } from "react";
import JobEditor from "../components/Decoder/JobEditor";
import JobStats from "../components/Decoder/JobStats";
import DiscoverLink from "../components/DiscoverLink";
import FadeIn from "../components/FadeIn";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Sources from "../components/Sources";
import TextDecode from "../components/TextDecode";
import { defaultJobAdvert } from "../data/advert.yaml";
import { subtitleDelay } from "../data/animation.yaml";
import styles from "./index.module.scss";

const IndexPage = () => {
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [words, updateWords] = useState([]);
  const [selection, setSelection] = useState({});

  return (
    <Layout>
      <Seo title="The Gender Bias Decoder"/>

      <Header
        className="editor"
        title={
          <TextDecode onFinish={setShowSubtitle}>
            The Gender Bias Decoder
          </TextDecode>
        }
        subtitle={
          <FadeIn show={showSubtitle} delay={subtitleDelay}>
            Check your text for hidden gender bias
          </FadeIn>
        }
      />

      <div className="container-fluid container-xl">
        <div className="row">
          <div className="col pt-4">
            <JobEditor value={defaultJobAdvert} onUpdateWords={updateWords} words={words}
                       selection={selection}/>
            <Sources/>
          </div>
          <div className={cn(styles.sidebar, "col-12 pt-4")}>
            <JobStats words={words} onClick={(key, index, length) => setSelection({ key, index, length })}/>

            <DiscoverLink/>
          </div>
        </div>
      </div>

      <Footer/>
    </Layout>
  );
};

export default IndexPage;
