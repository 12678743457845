import React from "react";
import FeminineHighlight from "../../../Highlight/FeminineHighlights"
import MasculineHighlight from "../../../Highlight/MasculineHighlight"

const SummaryText = ({ female, male }) => {

  if (female === male) {
    return (
      <div>
        <strong>Nice work!</strong> You have a balance of <MasculineHighlight bold/> and <FeminineHighlight bold/> words.
      </div>
    );
  }

  if (male > female) {
    return (
      <div>
        You're using more <MasculineHighlight bold/> words than <FeminineHighlight bold/> words.
      </div>
    );
  }

  return (
    <div>
      You're using more <FeminineHighlight bold/> words than <MasculineHighlight bold/> words.
    </div>
  );
};

export default SummaryText;
