import cn from "classnames";
import random from "lodash/random";
import sample from "lodash/sample";
import React, { useEffect, useState } from "react";
import useDimensions from "react-use-dimensions";
import Circle from "./Shapes/Circle";
import Diamond from "./Shapes/Diamond";
import Square from "./Shapes/Square";
import styles from "./styles.module.css";

const shapes = [
  Circle,
  Square,
  Diamond
];

const Index = ({ onFinish, children, delay }) => {
  const [animating, setAnimating] = useState(0);
  const [finished, setFinished] = useState(false);
  const [ref, { width, height }] = useDimensions();

  useEffect(() => {
    // if we already finished just stop
    if (finished) {
      return;
    }

    // if we have finished animating then stop
    if (animating >= delay) {
      onFinish();
      setFinished(true);
      return;
    }

    const timeout = Math.min(random(300, 700), delay - animating);

    // otherwise animate again
    const timer = setTimeout(() => {
      setAnimating(animating + timeout);
    }, timeout);

    return () => {
      clearTimeout(timer);
    };

  }, [delay, animating, finished, setAnimating, setFinished, onFinish]);

  // get a random shape unless its a space then keep the space
  const Shape = children === " " ? "span" : sample(shapes);

  return (
    <span className={styles.wrap} ref={ref}>
      {!finished && <Shape width={width} height={height}/>}

      <span className={cn({ [styles.hide]: !finished })}>
        {children}
      </span>
    </span>
  );
};

export default Index;
