import cn from "classnames";
import clamp from "lodash/clamp";
import countBy from "lodash/countBy";
import React from "react";
import styles from "./styles.module.css";
import SummaryText from "./SummaryText";

const WordRatio = ({ words }) => {
  const count = countBy(words, "type");
  const masculineCount = count.m || 0;
  const feminineCount = count.f || 0;

  const total = feminineCount + masculineCount;

  let masculineWidth = clamp((masculineCount / total) * 100, 3, 97);
  let feminineWidth = clamp((feminineCount / total) * 100, 3, 97);

  if (masculineCount === 0 && feminineCount > 0) {
    masculineWidth = 0;
    feminineWidth = 100;
  } else if (feminineCount === 0 && masculineCount > 0) {
    masculineWidth = 100;
    feminineWidth = 0;
  }


  return (
    <div className={styles.wrap}>

      <div className={styles.summary}>
        <SummaryText female={feminineCount} male={masculineCount}/>
      </div>

      <div className={styles.barContainer}>
        <div className={cn(styles.barWrap, {
          [styles.masculineBorder]: feminineCount === 0,
          [styles.feminineBorder]: masculineCount === 0
        })}>
          <div className={cn(styles.bar, styles.mBar, {
            [styles.hide]: masculineCount === 0,
            [styles.full]: feminineCount === 0
          })}
               style={{ width: `${masculineWidth}%` }}/>

          <div className={cn(styles.bar, styles.fBar, {
            [styles.hide]: feminineCount === 0,
            [styles.full]: masculineCount === 0
          })}
               style={{ width: `${feminineWidth}%` }}/>

        </div>
        <div className={styles.separator}/>
      </div>

      <div className={styles.numberWrap}>
        <span className={styles.mNumber}>
          {masculineCount > 0 && masculineCount}
        </span>
        <span className={styles.fNumber}>
          {feminineCount > 0 && feminineCount}
        </span>
      </div>

    </div>
  );
};

export default WordRatio;
