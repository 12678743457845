import cn from "classnames";
import React from "react";
import Scrollbars from "react-scrollbars-custom";
import styles from "./styles.module.css";

const HEIGHT = 456;

const CustomScrollBars = ({ editorState, words, children }) => {
  const trackYRenderer = (props) => {
    const { elementRef, style, children, ...restProps } = props;

    let blockMap = editorState.getCurrentContent().getBlockMap().toArray();
    const numberOfBlocks = blockMap.length;
    const blockHeight = HEIGHT / numberOfBlocks;

    let lastPosition = -100;

    return (
      <div {...restProps} ref={elementRef} className={styles.track}>

        <div className={styles.highlightWrap}>
          {blockMap.map((block, blockIndex) =>
            words.map(({ word, index, key, type }, wordIndex) => {
              if (key !== block.getKey()) {
                return null;
              }

              const length = block.getLength();
              const percent = (index / length);

              let top = (blockHeight * percent) + (blockIndex * blockHeight);

              if (top < lastPosition + 3) {
                top = lastPosition + 3;
              }

              lastPosition = top;

              return (
                <span
                  key={wordIndex}
                  className={cn(styles.highlight, styles[type])}
                  style={{
                    top
                  }}
                />
              );
            })
          )}
        </div>

        {children}
      </div>
    );
  };

  return (
    <Scrollbars
      style={{ height: HEIGHT }}
      noScrollX
      disableTracksWidthCompensation
      trackYProps={{
        renderer: trackYRenderer
      }}
      contentProps={{
        renderer: props => {
          const { elementRef, style, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                display: "block",
                height: "100%"
              }}
              className="ScrollbarsCustom-Scroller"
            />
          );
        }
      }}
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollBars;
