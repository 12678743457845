import React from "react";
import FeminineHighlight from "./FeminineHighlights";
import MasculineHighlight from "./MasculineHighlight";

const Highlight = ({ children, type }) => {
  let Element = type === "m" ? MasculineHighlight : FeminineHighlight;

  return (
    <Element>
      {children}
    </Element>
  );
};

export default Highlight;
