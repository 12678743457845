import { CompositeDecorator, ContentState, Editor, EditorState, SelectionState } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { Component, createRef } from "react";
import { findGenderedWords, moveSelectionToEnd } from "../../../util/words";
import ClearButton from "./ClearButton";
import CustomScrollBars from "./CustomScrollBars";
import { decoratedWords, HighlightSpan, highlightStrategy, PasteSpan, pasteStrategy } from "./decorators";
import styles from "./styles.module.scss";

class JobEditor extends Component {

  constructor (props) {
    super(props);

    this.editorRef = createRef();

    this.compositeDecorator = new CompositeDecorator([
      {
        strategy: highlightStrategy,
        component: HighlightSpan,
      },
      {
        strategy: pasteStrategy,
        component: PasteSpan,
      }
    ]);

    const editorState = this.createEditorState();

    this.state = {
      mounted: false,
      editorState,
    };

  }

  createEditorState () {
    const { onUpdateWords, value } = this.props;

    let editorState = EditorState.createWithContent(ContentState.createFromText(value), this.compositeDecorator);

    const words = [];

    const contentBlocks = editorState.getCurrentContent().getBlockMap();

    contentBlocks.forEach((contentBlock) => {
      const found = findGenderedWords(contentBlock);

      words.push(...found);
    });

    onUpdateWords(words);

    return editorState;
  }

  onChange = (editorState) => {
    const { onUpdateWords } = this.props;

    this.setState({ editorState });

    const contentBlocks = editorState.getCurrentContent().getBlockMap();
    const keys = contentBlocks.keySeq().toArray();

    contentBlocks.forEach((contentBlock) => {
      if (contentBlock.getLength() === 0) {
        delete decoratedWords[contentBlock.getKey()];
      }
    });


    let words = [];

    keys.forEach((key) => {
      if (decoratedWords[key]) {
        words = [
          ...words,
          ...decoratedWords[key]
        ];
      }
    });

    onUpdateWords(words);
  };

  onClear = () => {
    this.onChange(EditorState.createEmpty(this.compositeDecorator));

    setTimeout(() => {
      this.setFocus();
    }, 0);
  };

  setFocus = () => {
    const { current } = this.editorRef;

    if (current) {
      current.focus();
    }
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { selection } = this.props;
    const { editorState } = this.state;

    // force selection
    if (selection !== prevProps.selection) {
      const { key, index, length } = selection;

      const newEditorState = EditorState.forceSelection(editorState, new SelectionState({
        anchorKey: key,
        anchorOffset: index,
        focusKey: key,
        focusOffset: index + length
      }));

      this.setState({
        editorState: newEditorState
      });

      setTimeout(() => {
        window.getSelection().anchorNode.parentElement.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }, 10);
    }
  }

  componentDidMount () {
    const { editorState } = this.state;

    const newEditorState = moveSelectionToEnd(editorState);

    this.setState({
      mounted: true,
      editorState: newEditorState
    });

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  render () {
    const { editorState, mounted } = this.state;
    const { words } = this.props;

    const hasText = editorState.getCurrentContent().hasText();

    return (
      <div className={styles.wrap}>
        <div className={styles.editor}>
          <CustomScrollBars words={words} editorState={editorState}>
            <div id="jobEditorLabel" className="sr-only">
              Job Advert Input
            </div>
            <div className={styles.pad}>
              {mounted ? (
                <Editor
                  editorKey="job-editor"
                  editorState={editorState}
                  onChange={this.onChange}
                  ref={this.editorRef}
                  stripPastedStyles
                  ariaLabelledBy="jobEditorLabel"
                />
              ) : null}
            </div>
          </CustomScrollBars>
        </div>

        <ClearButton hasText={hasText} onClear={this.onClear}/>
      </div>
    );
  }
}

export default JobEditor;
