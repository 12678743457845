import shuffle from "lodash/shuffle";
import React from "react";
import { decoderTime } from "../../data/animation.yaml";
import LetterAnimation from "./LetterAnimation";

const TextDecode = React.memo(({ onFinish, children }) => {
  const letters = children.split("");
  const finished = [];

  const handleOnFinish = (index) => {
    finished.push(index);

    if (finished.length === letters.length) {
      onFinish(true);
    }
  };

  const timings = shuffle(letters.map((_, index) => {
    return (decoderTime / letters.length) * (index + 1);
  }));

  return (
    <>
      {letters.map((letter, index) => (
        <LetterAnimation key={index} onFinish={() => handleOnFinish(index)} delay={timings[index]}>
          {letter}
        </LetterAnimation>
      ))}
    </>
  );
});

export default TextDecode;
